const path = process.env.PUBLIC_URL + "/Realizacje";

export const projects = [
  {
    id: 1,
    title: "Bulaj",
    thumbnails: [path + "/Bulaj/Druk-scienny-Bulaj-thumb2.webp"],
    media: [
      {
        id: 1,
        type: "image",
        src: path + "/Bulaj/Druk-scienny-Bulaj4.webp",
        alt: "Druk scienny Bulaj 4",
      },
      { id: 5, type: "video", src: path + "/Bulaj/Druk-scienny-Bulaj2.mp4" },
      {
        id: 6,
        type: "video",
        src: path + "/Bulaj/Druk-scienny-Bulaj-timelapseHD.mp4",
      },
      {
        id: 2,
        type: "image",
        src: path + "/Bulaj/Druk-scienny-Bulaj-pict3.png",
        alt: "Druk scienny Bulaj 3",
      },
    ],
  },
  {
    id: 2,
    title: "Pirat",
    thumbnails: [path + "/Pirat/Druk-scienny-Pirat-thumb.webp"],
    media: [
      {
        id: 1,
        type: "image",
        src: path + "/Pirat/Druk-scienny-Pirat-pict1.png",
        alt: "Druk ścienny Pirat 1",
      },
      {
        id: 2,
        type: "image",
        src: path + "/Pirat/Druk-scienny-Pirat-pict2.png",
        alt: "Druk ścienny Pirat 2",
      },

      {
        id: 3,
        type: "image",
        src: path + "/Pirat/Druk-scienny-Pirat-pict3.png",
        alt: "Druk ścienny Pirat 3",
      },
      {
        id: 4,
        type: "video",
        src: path + "/Pirat/Druk-scienny-Pirat-timelapse.mp4",
      },
      {
        id: 5,
        type: "video",
        src: path + "/Pirat/Druk-scienny-Pirat-film2.mp4",
      },
    ],
  },
  {
    id: 7,
    title: "Księżyc",
    thumbnails: [path + "/Ksiezyc/Moon_thumb.webp"],
    media: [
      { id: 1, type: "image", src: path + "/Ksiezyc/Moon_pict1.jpg" },
      { id: 2, type: "video", src: path + "/Ksiezyc/Moon_video1.mp4" },
      { id: 2, type: "video", src: path + "/Ksiezyc/Moon_video2.mp4" },
    ],
  },
  {
    id: 3,
    title: "Madoka Kaname",
    thumbnails: [path + "/Madoka/Druk-scienny-Madoka-thumb.webp"],
    media: [
      {
        id: 1,
        type: "image",
        src: path + "/Madoka/Druk-scienny-Madoka-pict1.jpg",
        alt: "Druk ścienny Madoka Kaname 1",
      },
      {
        id: 2,
        type: "image",
        src: path + "/Madoka/Druk-scienny-Madoka-pict2.jpg",
        alt: "Druk ścienny Madoka Kaname 2",
      },
      {
        id: 3,
        type: "video",
        src: path + "/Madoka/Druk-scienny-Madoka-film1.mp4",
      },
      {
        id: 4,
        type: "video",
        src: path + "/Madoka/Druk-scienny-Madoka-film2.mp4",
      },
    ],
  },
  {
    id: 4,
    title: "Symfonia",
    thumbnails: [path + "/Symfonia/Druk-scienny-Symfonia-thumb2.webp"],
    media: [
      {
        id: 1,
        type: "image",
        src: path + "/Symfonia/Druk-scienny-Symfonia-pict1.jpg",
        alt: "Druk scienny Symfonia 1",
      },
      {
        id: 2,
        type: "video",
        src: path + "/Symfonia/Druk-scienny-Symfonia-film1.mp4",
      },
      {
        id: 3,
        type: "video",
        src: path + "/Symfonia/Druk-scienny-Symfonia-timelapse.mp4",
      },
    ],
  },
  {
    id: 5,
    title: "Wieloryb",
    thumbnails: [path + "/Wieloryb/wieloryb-thumb.webp"],
    media: [
      {
        type: "image",
        src: path + "/Wieloryb/Druk-scienny-wieloryb-pict1.jpg",
      },
      {
        type: "image",
        src: path + "/Wieloryb/Druk-scienny-wieloryb-pict2.jpg",
      },
      {
        type: "image",
        src: path + "/Wieloryb/Druk-scienny-wieloryb-pict3.jpg",
      },
    ],
  },
  {
    id: 6,
    title: "Frida&Kora",
    thumbnails: [path + "/Frida&Kora/Druk-scienny-Realizacje-Frida-thumb.webp"],
    media: [
      {
        id: 1,
        type: "image",
        src: path + "/Frida&Kora/Druk-scienny-realizacje-Frida1.jpg",
      },
      {
        id: 2,
        type: "image",
        src: path + "/Frida&Kora/Druk-scienny-Realizacje-Kora1.jpg",
      },
      {
        id: 3,
        type: "image",
        src: path + "/Frida&Kora/Druk-scienny-Realizacje-Frida&Kora.jpg",
      },
      {
        id: 4,
        type: "video",
        src: path + "/Frida&Kora/Druk-scienny-Realizacje-Frida-film2.mp4",
      },
      {
        id: 5,
        type: "video",
        src: path + "/Frida&Kora/Druk-scienny-Realizacje-Kora-film2.mp4",
      },
    ],
  },

  {
    id: 8,
    title: "Krakowska Szkoła Tańca",
    thumbnails: [path + "/KST/KST-thumb.webp"],
    media: [
      {
        id: 1,
        type: "image",
        src: path + "/KST/Druk-scienny-Krakowska-Szkola-Tanca-pict1.jpg",
      },
      {
        id: 2,
        type: "video",
        src: path + "/KST/Druk-scienny-Krakowska-Szkola-Tanca-film1.mp4",
      },
    ],
  },
  {
    id: 9,
    title: "Octagon",
    thumbnails: [path + "/Octagon/octagon-thumb2.webp"],
    media: [
      {
        id: 1,
        type: "image",
        src: path + "/Octagon/Druk-scienny-octagon-pict1.jpg",
      },
      {
        id: 2,
        type: "video",
        src: path + "/Octagon/Druk-scienny-octagon-film1.mp4",
      },
      {
        id: 3,
        type: "video",
        src: path + "/Octagon/Druk-scienny-octagon-film2.mp4",
      },
    ],
  },
  {
    id: 10,
    title: "Las",
    thumbnails: [path + "/Las/Druk-scienny-Las-thumb.webp"],
    media: [
      {
        id: 1,
        type: "image",
        src: path + "/Las/Druk-scienny-Las-pict1.jpg",
      },
      {
        id: 2,
        type: "video",
        src: path + "/Las/Druk-scienny-las-video2.mp4",
      },
      {
        id: 3,
        type: "video",
        src: path + "/Las/Druk-scienny-Las-timelapse.mp4",
      },
    ],
  },
  {
    id: 11,
    title: "KZ Studio Projekt ",
    thumbnails: [path + "/KZ/Druk-scienny-KZ-thumb.webp"],
    media: [
      {
        id: 1,
        type: "video",
        src: path + "/KZ/Druk-scienny-KZ-video.mp4",
      },
      {
        id: 2,
        type: "image",
        src: path + "/KZ/Druk-scienny-KZ-pict1.jpg",
      },
    ],
  },
  {
    id: 12,
    title: "Artformer ",
    thumbnails: [path + "/Artformer/Druk-scienny-Artformer-thumb.webp"],
    media: [
      {
        id: 1,
        type: "video",
        src: path + "/Artformer/Druk-scienny-Artformer-timelapse.mp4",
      },
      {
        id: 2,
        type: "video",
        src: path + "/Artformer/Druk-scienny-Artformer-video.mp4",
      },
      {
        id: 3,
        type: "image",
        src: path + "/Artformer/Druk-scienny-Artformer-pict1.jpg",
      },
      {
        id: 4,
        type: "image",
        src: path + "/Artformer/Druk-scienny-Artformer-pict2.jpg",
      },
    ],
  },
  {
    id: 13,
    title: "Mountain ",
    thumbnails: [path + "/Mountain/Druk-scienny-Mountain-thumb.webp"],
    media: [
      {
        id: 1,
        type: "video",
        src: path + "/Mountain/Druk-scienny-Mountain-timelapse.mp4",
      },
      {
        id: 2,
        type: "video",
        src: path + "/Mountain/Druk-scienny-Mountain-vertical.mp4",
      },
      {
        id: 3,
        type: "image",
        src: path + "/Mountain/Druk-scienny-Mountain-pict1.jpg",
      },
      {
        id: 4,
        type: "image",
        src: path + "/Mountain/Druk-scienny-Mountain-pict2.jpg",
      },
    ],
  },
];
